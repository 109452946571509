<template>
  <div class="overflow-auto">
    <table class="table table-sm table-stripe mb-0 table-custom border">
      <thead>
        <tr>
          <th colspan="1"></th>
          <th class="text-center" colspan="4">Client Answers</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!noPainReported">
          <td colspan="2">
            Per your pain report, what time of day did it occur?
          </td>
          <td colspan="5">
            <div>
              <select
                required
                :disabled="isFilled"
                class="form-control"
                :value="dataToSend.pain_time"
                @input="setVal($event, 'pain_time')"
              >
                <option
                  v-for="(painOccurOption, index) in painOccurOptions"
                  :value="painOccurOption.value"
                  :disabled="index === 0"
                  :selected="index === 0"
                  :key="index"
                >
                  {{ painOccurOption.label }}
                </option>
              </select>
            </div>
          </td>
        </tr>
        <tr v-if="!noPainReported">
          <td colspan="2">
            Please rate your current level of pain
            <br />
            <span class="text-muted small"> (0 No Pain - 10 Worst Pain)</span>
          </td>
          <td colspan="2">
            <div class="rangeSlider">
              <span style="min-width: 16px">{{
                dataToSend.pain_rate == -1 ? "N/A" : dataToSend.pain_rate || 0
              }}</span>
              <input
                required
                type="range"
                class="form-range"
                min="-1"
                max="10"
                step="1"
                id="customRange3"
                :value="dataToSend.pain_rate || 0"
                :disabled="isFilled"
                @input="setVal($event, 'pain_rate')"
              />
              <span>{{ 10 }}</span>
            </div>
            <div class="text-center mt-2 font-weight-bold">
              {{
                painLevels[
                  dataToSend.pain_rate == -1 ? 11 : dataToSend.pain_rate || 0
                ]
              }}
            </div>
          </td>
        </tr>
        <tr v-if="!noPainReported">
          <td colspan="2" class="border-0 p-0"></td>
          <td colspan="6" class="border-0 p-0 text-center">
            <img
              src="@/assets/img/pain-scale.png"
              alt="Pain Scale"
              class="mb-4 mt-3"
              style="max-width: 90%; max-height: 220px"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            Please rate your current level of fatigue
            <br />
            <span class="text-muted small">
              (0 No Fatigue - 10 Most Sever Fatigue)</span
            >
          </td>
          <td colspan="2">
            <div class="rangeSlider">
              <span style="min-width: 16px">{{
                dataToSend.fatigue_rate == -1
                  ? "N/A"
                  : dataToSend.fatigue_rate || 0
              }}</span>
              <input
                required
                type="range"
                class="form-range"
                min="-1"
                max="10"
                step="1"
                id="customRange3"
                :disabled="isFilled"
                :value="dataToSend.fatigue_rate || 0"
                @input="setVal($event, 'fatigue_rate')"
              />
              <span>{{ 10 }}</span>
            </div>
            <div class="text-center mt-2 font-weight-bold">
              {{
                fatigueLevels[
                  dataToSend.fatigue_rate == -1
                    ? 11
                    : dataToSend.fatigue_rate || 0
                ]
              }}
              {{ fatigueLevels[dataToSend.fatigue_rate || 0] }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="border-0 p-0"></td>
          <td colspan="6" class="border-0 p-0 text-center">
            <img
              src="@/assets/img/fatigue-scale.png"
              alt="Fatigue Scale"
              style="width: 90%"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2">How many hours did you sleep the previous night</td>
          <td colspan="5">
            <div>
              <select
                required
                :value="dataToSend.sleep_hours"
                class="form-control"
                :disabled="isFilled"
                @input="setVal($event, 'sleep_hours')"
              >
                <option
                  v-for="(hoursSleep, index) in hoursSleepOptions"
                  :value="hoursSleep.value"
                  :disabled="index === 0"
                  :selected="index === 0"
                  :key="index"
                >
                  {{ hoursSleep.label }}
                </option>
              </select>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            Please rate your mood from 0-10 <br />
            <span class="text-muted small"> (0 Worst Mood - 10 Best Mood)</span>
          </td>
          <td colspan="2">
            <div class="rangeSlider">
              <span style="min-width: 16px">{{
                dataToSend.mood_rate == -1 ? "N/A" : dataToSend.mood_rate || 0
              }}</span>
              <input
                required
                type="range"
                class="form-range"
                min="-1"
                max="10"
                step="1"
                id="customRange3"
                :disabled="isFilled"
                :value="dataToSend.mood_rate || 0"
                @input="setVal($event, 'mood_rate')"
              />
              <span>{{ 10 }}</span>
            </div>
            <div class="text-center mt-2 font-weight-bold">
              {{
                moodLevels[
                  dataToSend.mood_rate == -1 ? 11 : dataToSend.mood_rate || 0
                ]
              }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="border-0 p-0"></td>
          <td colspan="6" class="border-0 p-0 text-center">
            <img
              src="@/assets/img/mood-scale.png"
              alt="Mood Scale"
              class="mb-4 mt-3"
              style="width: 90%"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2">Anxiety Reported</td>
          <td colspan="5">
            <div>
              <select
                required
                class="form-control"
                :disabled="isFilled"
                :value="dataToSend.client_anxiety"
                @input="setVal($event, 'client_anxiety')"
              >
                <option
                  v-for="(anxietyOption, index) in anxietyOptions"
                  :value="anxietyOption.value"
                  :disabled="index === 0"
                  :selected="index === 0"
                  :key="index"
                >
                  {{ anxietyOption.label }}
                </option>
              </select>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            if applicable, please rate the severity of your headache <br />
            <span class="text-muted small">
              (0 being love level headache - 10 being worst possible
              headache)</span
            >
          </td>
          <td colspan="5">
            <div class="rangeSlider">
              <span style="min-width: 16px">{{
                dataToSend.headaches_rate == -1
                  ? "N/A"
                  : dataToSend.headaches_rate || 0
              }}</span>
              <input
                required
                type="range"
                class="form-range"
                min="-1"
                max="10"
                step="1"
                :disabled="isFilled"
                id="customRange3"
                :value="dataToSend.headaches_rate || 0"
                @input="setVal($event, 'headaches_rate')"
              />
              <span>{{ 10 }}</span>
            </div>
            <div class="text-center mt-2 font-weight-bold">
              {{
                headacheLevels[
                  dataToSend.headaches_rate == -1
                    ? 11
                    : dataToSend.headaches_rate || 0
                ]
              }}
            </div>
            <div>
              <i
                :class="
                  headacheEmojis[
                    dataToSend.headaches_rate == -1
                      ? 11
                      : dataToSend.headaches_rate || 0
                  ]
                "
              ></i>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="border-0 py-0" style="vertical-align: top">
            <button
              class="btn btn-sm btn-157477 mb-4"
              @click.prevent="showHeadacheLevels()"
            >
              Headache Levels<i
                class="fa fa-question-circle ml-3 pointer-cursor"
              ></i>
            </button>
          </td>
          <td colspan="6" class="border-0 p-0"></td>
        </tr>
        <tr>
          <td colspan="2">Activity Category</td>
          <td colspan="5">
            <CustomVueTagsInput
              :disabled="isFilled"
              :activityCategoryTags="activityCategoryTags"
              :activityCategoryAutocompleteItems="
                activityCategoryAutocompleteItems
              "
              @setTags="setTags"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CustomVueTagsInput from "../../helpers/CustomVueTagsInput.vue";
import Swal from "sweetalert2";
import CONSTANT from "../../symptoms/constants";

export default {
  components: {
    CustomVueTagsInput,
  },
  props: ["noPainReported"],
  data() {
    return {
      questionKeys: [
        "pain_time",
        "pain_rate",
        "fatigue_rate",
        "mood_rate",
        "headaches_rate",
        "sleep_hours",
        "client_anxiety",
        "activity_categories",
      ],
      dataToSend: {
        pain_rate: -1,
        fatigue_rate: -1,
        mood_rate: -1,
        headaches_rate: -1,
      },
      painOccurOptions: [
        { label: "Pain Time", value: "" },
        { label: "Morning", value: 1 },
        { label: "Afternoon", value: 2 },
        { label: "Night", value: 3 },
        { label: "Morning & Afternoon", value: 4 },
        { label: "Morning & Night", value: 5 },
        { label: "Afternoon & Night", value: 6 },
        { label: "All The Time", value: 7 },
      ],
      hoursSleepOptions: [],
      anxietyOptions: [
        { label: "Anxiety Reported", value: "" },
        { label: "Anxiety", value: 1 },
        { label: "No Anxiety", value: 2 },
      ],
      activityCategoryAutocompleteItems: [],
      activityCategoryTags: [],
      painLevels: CONSTANT.PAIN_LEVELS,
      headacheEmojis: CONSTANT.HEADACHE_LEVEL_EMOJI,
      headacheLevels: CONSTANT.HEADACHE_LEVELS,
      fatigueLevels: CONSTANT.FATIGUE_LEVELS,
      moodLevels: CONSTANT.MOOD_LEVELS,
    };
  },

  beforeMount() {
    this.hoursSleepOptions.push({ label: "Number of Hours", value: "" });
    for (let i = 0; i <= 24; i++) {
      this.hoursSleepOptions.push({ label: `${i} H`, value: i });
    }
    // fill activity category autocomplete items
    const _autocompleteItems = [];
    this.activityCategories.forEach((item) => {
      _autocompleteItems.push({
        text: item.name,
        value: item.id,
        id: item.id,
      });
    });
    this.activityCategoryAutocompleteItems = _autocompleteItems;
  },
  mounted() {
    const vm = this;
    let tempObject = {};
    this.questionKeys.forEach((element) => {
      tempObject[element] = JSON.parse(
        JSON.stringify(vm.clientQuestionnaire[element])
      );
    });
    if (this.clientQuestionnaire.activity_categories) {
      const _activityCategoryTags = [];
      this.clientQuestionnaire.activity_categories.forEach((tag) => {
        _activityCategoryTags.push({
          text: tag.name,
          value: tag.id,
          id: tag.id,
        });
      });
      this.activityCategoryTags = _activityCategoryTags;
    } else {
      this.activityCategoryTags = [];
    }
    if (tempObject.pain_rate == null) {
      tempObject.pain_rate = -1;
    }
    if (tempObject.fatigue_rate == null) {
      tempObject.fatigue_rate = -1;
    }
    if (tempObject.mood_rate == null) {
      tempObject.mood_rate = -1;
    }
    if (tempObject.headaches_rate == null) {
      tempObject.headaches_rate = -1;
    }
    this.dataToSend = tempObject;
    this.setCurrentClientQuestionnaire({
      ...this.clientQuestionnaire,
      ...this.dataToSend,
    });
  },
  computed: {
    ...mapState({
      clientQuestionnaire: (state) =>
        state.patients.symptomsDiary.currentClientQuestionnaire.data,
      activityCategories: (state) =>
        state.patients.symptomsDiary.activityCategories.data,
    }),
    isFilled: function () {
      return this.clientQuestionnaire.isFilled;
    },
  },
  methods: {
    ...mapActions({
      setCurrentClientQuestionnaire:
        "patients/symptomsDiary/setCurrentClientQuestionnaire",
    }),
    setVal: function (event, valFor) {
      this.dataToSend[valFor] = event.target.value;
      this.setCurrentClientQuestionnaire({
        ...this.clientQuestionnaire,
        ...this.dataToSend,
      });
    },
    setTags: function (newTags) {
      this.dataToSend["activity_categories"] = newTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.text,
        };
      });
      this.setCurrentClientQuestionnaire({
        ...this.clientQuestionnaire,
        ...this.dataToSend,
      });
    },
    showHeadacheLevels: function () {
      let tableContent = "";
      this.headacheLevels.forEach((level, index) => {
        tableContent += `<tr><td class='p-3'>${
          index == 11 ? "-" : index
        }</td><td class='p-3'>${level}</td><td class='px-3 py-1'><i class='${
          this.headacheEmojis[index]
        }'></i></td></tr>`;
      });
      Swal.fire({
        title: "Headache Levels",
        html: `<table class='table card text-left overflow-hidden'><tr class='font-weight-bold'><td class='p-3'>Key</td><td class='p-3'>Description</td><td class="p-3">Emoji</td></tr>${tableContent}</table>`,
        width: "auto",
        padding: "0",
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          title: "pt-4",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.border {
  border-width: 0.5px;
}
table {
  thead {
    td {
      text-align: center;
    }
  }
  tbody {
    td {
      div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .rangeSlider {
      gap: 10px;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>
