<template>
  <div class="container p-0">
    <div class="selectSession">
      <label class="font-weight-bold pt-2 pl-0 col-12">Date of report</label>
      <alert v-if="loading" class="py-2 light-shadow col-12"></alert>
      <select
        v-model="selectedSession"
        v-if="!loading"
        class="form-control light-shadow"
      >
        <option value="-1" selected disabled>Date of report</option>
        <option
          v-for="sessionOption in getSessionOptions"
          :key="sessionOption.value"
          :value="sessionOption.value"
        >
          {{ sessionOption.label }}
        </option>
      </select>
    </div>
    <alert
      v-if="currentClientQuestionnaireLoading"
      class="py-8-px light-shadow col-12"
    ></alert>
    <div
      class="custom-control custom-checkbox my-3"
      v-if="
        selectedSession != -1 && !currentClientQuestionnaireLoading && !isFilled
      "
    >
      <input
        type="checkbox"
        class="custom-control-input"
        id="no_pain_reported"
        v-model="noPainReported"
        @click="toggleNoPainReported"
      />
      <label class="custom-control-label mr-3 mb-3" for="no_pain_reported"
        >No Pain Reported</label
      >
    </div>
    <form
      @submit.prevent="sendData"
      v-if="selectedSession != -1 && !currentClientQuestionnaireLoading"
      class="card theme-card p-3 p-md-4 p-lg-4 col-12"
    >
      <LocationOfPain v-if="activeStep === 1 && !noPainReported" />
      <RestOfSteps v-if="activeStep === 2" :noPainReported="noPainReported" />
      <div class="buttons mt-3">
        <button
          v-if="activeStep == 2 && !noPainReported"
          type="button"
          class="btn btn-secondary"
          @click="handleStep(-1)"
        >
          Back
        </button>
        <div>
          <button
            v-if="activeStep == 1"
            type="button"
            class="btn btn-theme px-5"
            @click="handleStep(1)"
          >
            Next
          </button>
          <save
            v-if="activeStep == 2"
            classes="save-button-60 btn btn-theme"
            :saving="saving"
            :disabled="isFilled"
            >Save Questionnaire</save
          >
        </div>
      </div>
    </form>
    <div class="col p-0 mb-4" v-if="currentClientQuestionnaire.isFilled">
      <div class="mb-3">
        Created by
        <b class="text-danger">{{
          currentClientQuestionnaire.created_by || "-"
        }}</b>
        at
        <span class="text-secondary">{{
          prettyDate(currentClientQuestionnaire.created_at)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import LocationOfPain from "./steps/LocationOfPain.vue";
import RestOfSteps from "./steps/RestOfSteps.vue";
import Swal from "sweetalert2";

export default {
  components: { LocationOfPain, RestOfSteps },
  data() {
    return {
      selectedSession: -1,
      activeStep: 1,
      latestClientQuestionnaire: {},
      noPainReported: false,
    };
  },
  mounted() {
    this.setCurrentClientQuestionnaire({});
    this.getClientAppointments(this.currentPatient.user_id);
    this.getActivityCategories();
  },
  watch: {
    selectedSession() {
      const vm = this;
      this.setStep(1);
      this.getClientQuestionnaire(this.selectedSession).then(() => {
        vm.latestClientQuestionnaire = JSON.parse(
          JSON.stringify(vm.currentClientQuestionnaire)
        );
        vm.noPainReported = vm.currentClientQuestionnaire.no_pain_reported;
        if (vm.noPainReported) {
          vm.setStep(2);
        }
      });
    },
  },
  created() {
    window.addEventListener("beforeunload", this.whenRefresh);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.whenRefresh);
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.patients.patient,
      clientAppointments: (state) =>
        state.patients.symptomsDiary.clientAppointments.data,
      loading: (state) =>
        state.patients.symptomsDiary.clientAppointments.loading,
      currentClientQuestionnaireLoading: (state) =>
        state.patients.symptomsDiary.currentClientQuestionnaire.loading,
      currentClientQuestionnaire: (state) =>
        state.patients.symptomsDiary.currentClientQuestionnaire.data,
      saving: (state) => state.patients.symptomsDiary.saving,
    }),
    getSessionOptions: function () {
      const newQuestionnaire = [...this.clientAppointments];
      return newQuestionnaire.map((apt) => {
        const option = {
          value: apt.id,
        };
        let label = dayjs(apt.start).utc().format("MMM D, YYYY hh:mm A");
        label =
          label + " - " + dayjs(apt.end).utc().format("MMM D, YYYY hh:mm A");
        if (apt.service_name) {
          label = label + " (" + apt.service_name + ")";
        }
        option.label = label;
        return option;
      });
    },
    isFilled: function () {
      return this.currentClientQuestionnaire.isFilled;
    },
  },
  methods: {
    ...mapActions({
      getClientAppointments: "patients/symptomsDiary/getClientAppointments",
      getClientQuestionnaire: "patients/symptomsDiary/getClientQuestionnaire",
      saveClientQuestionnaire: "patients/symptomsDiary/saveClientQuestionnaire",
      setCurrentClientQuestionnaire:
        "patients/symptomsDiary/setCurrentClientQuestionnaire",
      getActivityCategories: "patients/symptomsDiary/getActivityCategories",
    }),
    handleStep: function (step) {
      this.activeStep += step;
    },
    setStep: function (value) {
      this.activeStep = value;
    },
    sendData: function () {
      if (this.isFilled) {
        return;
      }
      const vm = this;
      if (
        this.currentClientQuestionnaire.body_locations &&
        !this.currentClientQuestionnaire.body_locations.length &&
        !this.noPainReported
      ) {
        Swal.fire({
          title: "Error",
          html: `Please select one or more location of pain`,
          icon: "error",
        });
        this.setStep(1);
        return;
      }
      if (
        this.currentClientQuestionnaire.activity_categories &&
        !this.currentClientQuestionnaire.activity_categories.length
      ) {
        Swal.fire({
          title: "Error",
          html: `Please select one or more activity category`,
          icon: "error",
        });
        return;
      }
      const copy = JSON.parse(JSON.stringify(this.currentClientQuestionnaire));
      const questionKeys = [
        "pain_rate",
        "fatigue_rate",
        "mood_rate",
        "headaches_rate",
      ];
      questionKeys.forEach((item) => {
        if (!copy[item]) {
          copy[item] = "0";
        }
      });
      if (this.noPainReported) {
        copy.time_pain = null;
        copy.pain_rate = null;
      }
      copy.no_pain_reported = this.noPainReported;
      this.setCurrentClientQuestionnaire(copy);
      if (!this.validateQuestionnaire(copy)) {
        return;
      }
      this.saveClientQuestionnaire({
        id: this.currentClientQuestionnaire.id,
        data: this.currentClientQuestionnaire,
      }).then((res) => {
        if (res) {
          this.latestClientQuestionnaire = JSON.parse(
            JSON.stringify(this.currentClientQuestionnaire)
          );
          window.removeEventListener("beforeunload", vm.whenRefresh);
        }
      });
    },
    whenRefresh: function (e) {
      if (
        // eslint-disable-next-line no-undef
        _.isEqual(
          this.currentClientQuestionnaire,
          this.latestClientQuestionnaire
        ) === false
      ) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    toggleNoPainReported: function () {
      if (!this.noPainReported && this.activeStep === 1) {
        this.setStep(2);
      }
      if (this.noPainReported && this.activeStep !== 1) {
        this.setStep(1);
      }
    },
    validateQuestionnaire: function (sentQuestionnaire) {
      const questionnaireFieldsMapping = [
        {
          fieldName: "sleep_hours",
          step: 2,
          message: "The number of sleeping hours field is required",
        },
        {
          fieldName: "client_anxiety",
          step: 2,
          message: "The anxiety reported field is required",
        },
      ];
      if (!this.noPainReported) {
        questionnaireFieldsMapping.unshift(
          ...[
            {
              fieldName: "pain_time",
              step: 2,
              message: "The time of pain field is required",
            },
          ]
        );
      }
      for (let i = 0; i < questionnaireFieldsMapping.length; i++) {
        const item = questionnaireFieldsMapping[i];
        if (!sentQuestionnaire[item.fieldName]) {
          this.fireError(item.message);
          this.setStep(item.step);
          return false;
        }
      }
      return true;
    },
    fireError: function (message) {
      Swal.fire({
        title: "Error",
        html: message,
        icon: "error",
      });
    },
    prettyDate: function (date) {
      return dayjs(date).format("MMM DD, YYYY  hh:mm A");
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  min-height: 60vh;
}
.selectSession {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  .form-control {
    width: 100%;
  }
}

.buttons {
  width: 100%;
  &:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
