<template>
  <div class="overflow-auto nice-scroll">
    <div class="container">
      <h1>Location of pain</h1>
      <div class="center-div">
        <div class="mapArea">
          <img
            src="@/assets/img/BodyMap.png"
            alt="bodyMap"
            usemap="#image-map"
            class="bodyMap"
          />
          <map name="image-map" @click.prevent="handleSelectedArea">
            <area
              id="1"
              alt="Front Left Head"
              title="Front Left Head"
              href=""
              coords="200,3,202,84,195,84,190,80,186,75,182,71,179,63,175,56,172,50,174,40,174,31,174,24,176,15,182,10,190,4"
              shape="poly"
            />
            <area
              id="2"
              alt="Front Right Head"
              title="Front Right Head"
              href=""
              coords="202,3,202,84,209,81,214,77,218,72,220,65,222,58,230,58,231,50,231,41,230,32,229,22,226,15,215,6"
              shape="poly"
            />
            <area
              target=""
              id="3"
              alt="Front Neck"
              title="Front Neck"
              href=""
              coords="154,102,159,104,164,106,171,108,179,107,188,107,195,107,201,112,209,109,218,106,228,106,243,106,251,102,239,96,231,90,226,82,219,72,204,85,196,87,190,81,183,75,178,93"
              shape="poly"
            />
            <area
              target=""
              id="4"
              alt="Front Left Shoulder"
              title="Front Left Shoulder"
              href=""
              coords="112,136,120,136,126,138,132,142,139,146,142,150,146,154,156,154,162,154,167,155,172,155,182,154,189,154,196,154,202,154,202,142,201,134,201,125,200,117,198,111,189,107,182,108,170,109,163,106,153,104,144,103,134,104,126,106,121,113,118,118,114,125"
              shape="poly"
            />
            <area
              target=""
              id="5"
              alt="Front Right Shoulder"
              title="Front Right Shoulder"
              href=""
              coords="202,155,207,156,215,156,222,156,227,155,233,155,242,155,250,155,256,156,260,153,268,148,272,144,280,140,285,137,292,133,291,125,287,119,284,111,278,105,270,102,260,102,251,101,242,105,234,107,223,108,212,108,207,110,203,114"
              shape="poly"
            />
            <area
              target=""
              id="6"
              alt="Front Left Upper Arm "
              title="Front Left Upper Arm "
              href=""
              coords="82,194,86,200,91,206,98,207,107,211,110,205,116,199,120,193,126,187,129,180,133,175,137,170,139,163,140,155,138,147,131,145,123,141,116,135,112,135,110,143,104,151,101,157,98,164,96,171,92,180,88,187"
              shape="poly"
            />
            <area
              target=""
              id="8"
              alt="Front Left Lower Arm "
              title="Front Left Lower Arm "
              href=""
              coords="34,274,40,279,48,282,54,279,60,274,64,268,71,262,76,255,80,250,86,245,91,239,96,232,100,226,108,211,98,207,88,203,83,195,76,201,69,212,63,224,58,236,55,246,49,257,42,268"
              shape="poly"
            />
            <area
              target=""
              id="7"
              alt="Front Right Upper Arm "
              title="Front Right Upper Arm "
              href=""
              coords="297,206,304,205,314,203,319,193,316,183,313,172,308,162,301,152,294,135,283,139,276,143,269,147,262,152,263,159,264,164,269,169,273,176,279,182,283,188,289,193,293,198"
              shape="poly"
            />
            <area
              target=""
              id="9"
              alt="Front Right Lower Arm "
              title="Front Right Lower Arm "
              href=""
              coords="354,280,361,275,367,272,373,268,365,260,353,242,349,236,341,224,337,209,330,199,320,193,318,202,311,205,304,207,297,207,301,219,307,229,322,245,329,252,334,257,342,263,344,269"
              shape="poly"
            />
            <area
              target=""
              id="10"
              alt="Front Left Hand "
              title="Front Left Hand "
              href=""
              coords="33,274,27,278,20,279,15,285,9,292,8,302,20,293,17,300,12,309,8,317,13,324,24,327,32,321,39,314,45,305,51,296,53,285"
              shape="poly"
            />
            <area
              target=""
              id="11"
              alt="Front Right Hand "
              title="Front Right Hand "
              href=""
              coords="354,282,355,289,356,294,361,300,367,306,370,314,374,317,382,323,380,316,389,325,395,322,400,317,402,311,399,305,394,299,391,294,399,298,404,300,405,295,397,287,393,282,387,276,376,270,368,271,360,274"
              shape="poly"
            />
            <area
              target=""
              id="12"
              alt="Left Chest"
              title="Left Chest"
              href=""
              coords="151,229,157,229,163,222,170,214,176,207,179,200,182,193,187,181,192,174,197,171,202,169,203,162,200,155,190,155,183,156,176,156,168,157,159,156,148,156,144,154,143,164,147,175,150,186,149,196,150,205,150,214,150,220"
              shape="poly"
            />
            <area
              target=""
              id="13"
              alt="Right Chest"
              title="Right Chest"
              href=""
              coords="202,156,203,164,202,171,208,172,212,179,217,183,219,191,222,198,225,207,229,213,234,219,243,227,251,230,247,219,247,208,250,199,252,190,254,184,258,179,259,170,261,163,262,155"
              shape="poly"
            />
            <area
              target=""
              id="14"
              alt="Left Abdomen "
              title="Left Abdomen "
              href=""
              coords="151,231,151,238,150,244,148,251,152,258,159,267,163,270,170,275,176,281,185,282,193,282,200,283,200,272,199,259,199,242,200,230,200,217,200,208,201,198,201,185,201,176,201,170,193,173,190,179,185,190,182,197,180,203,174,210,169,216,164,221,158,228"
              shape="poly"
            />
            <area
              target=""
              id="15"
              alt="Right Abdomen "
              title="Right Abdomen "
              href=""
              coords="201,282,207,282,217,282,226,284,231,280,240,271,248,262,252,256,251,244,250,232,241,226,235,221,228,214,222,200,218,188,213,177,206,171,202,171"
              shape="poly"
            />
            <area
              target=""
              id="16"
              alt="Genitalia"
              title="Genitalia"
              href=""
              coords="177,284,181,288,191,301,194,307,199,312,202,317,208,308,212,303,214,297,222,290,223,284"
              shape="poly"
            />
            <area
              target=""
              id="17"
              alt="Front Left Thigh"
              title="Front Left Thigh"
              href=""
              coords="147,251,144,257,143,266,142,274,139,287,139,295,140,304,139,314,139,322,139,329,140,340,142,355,142,363,146,371,148,380,150,389,154,397,156,404,160,410,168,414,173,415,180,416,186,416,195,412,195,402,198,392,199,379,199,368,199,356,200,344,200,332,202,319,189,302,186,292,159,267"
              shape="poly"
            />
            <area
              target=""
              id="18"
              alt="Front Right Thigh"
              title="Front Right Thigh"
              href=""
              coords="203,317,203,326,202,334,202,346,205,361,206,369,208,382,208,397,210,405,212,412,221,412,231,413,239,409,246,401,248,393,252,383,256,367,259,353,261,336,260,316,260,299,258,282,256,268,253,257,229,282,220,291,211,301"
              shape="poly"
            />
            <area
              target=""
              id="19"
              alt="Left Shin"
              title="Left Shin"
              href=""
              coords="155,457,153,465,154,475,155,486,158,496,158,504,160,515,163,529,163,540,164,555,164,568,170,571,178,569,185,569,190,571,190,561,188,550,190,527,191,513,194,499,197,490,195,477,193,465,194,453,182,448,174,448,163,449"
              shape="poly"
            />
            <area
              target=""
              id="20"
              alt="Right Shin"
              title="Right Shin"
              href=""
              coords="208,450,207,458,204,467,204,478,207,489,207,498,209,506,210,513,215,526,214,534,213,546,212,553,212,560,208,573,213,569,218,569,223,569,229,569,235,569,239,565,235,555,234,548,235,537,237,529,239,523,242,515,244,502,244,490,246,480,247,467,246,455,242,447,232,446,219,446"
              shape="poly"
            />
            <area
              target=""
              id="21"
              alt="Front Left Foot"
              title="Front Left Foot"
              href=""
              coords="162,570,160,577,154,581,151,586,146,592,142,597,139,603,140,608,144,612,150,615,155,611,162,614,171,611,176,609,178,602,182,595,188,591,192,586,194,578,190,568,179,569,171,569"
              shape="poly"
            />
            <area
              target=""
              id="22"
              alt="Front Right Foot"
              title="Front Right Foot"
              href=""
              coords="206,570,206,576,206,586,209,593,216,595,222,598,227,601,222,596,230,605,235,610,243,613,251,616,260,614,266,610,270,604,267,596,254,588,245,582,240,574,233,570"
              shape="poly"
            />
            <area
              target=""
              id="49"
              alt="Left Knee"
              title="Left Knee"
              href=""
              coords="154,405,156,412,158,420,155,428,157,435,157,441,156,449,153,456,171,449,183,447,187,450,193,453,194,442,196,433,195,425,196,418,194,409,190,414,183,416,172,417"
              shape="poly"
            />
            <area
              target=""
              id="50"
              alt="Right Knee"
              title="Right Knee"
              href=""
              coords="209,411,209,420,209,427,208,434,208,442,208,450,214,447,224,446,232,445,240,447,246,450,244,436,244,424,246,413,248,403,242,407,234,412,224,412,215,412"
              shape="poly"
            />

            <area
              target=""
              id="23"
              alt="Back Left Head"
              title="Back Left Head"
              href=""
              coords="653,4,655,14,655,23,655,73,646,71,631,64,626,56,622,47,631,46,629,38,626,28,629,19,635,11,644,7"
              shape="poly"
            />
            <area
              target=""
              id="24"
              alt="Back Right Head"
              title="Back Right Head"
              href=""
              coords="655,3,656,74,670,71,680,66,685,59,688,50,682,42,683,32,680,20,671,9"
              shape="poly"
            />
            <area
              target=""
              id="25"
              alt="Back Neck"
              title="Back Neck"
              href=""
              coords="638,69,637,77,631,87,622,93,613,99,604,101,622,104,631,104,650,107,659,104,676,107,689,107,698,104,707,102,688,95,680,87,677,77,674,66,664,72,653,74"
              shape="poly"
            />
            <area
              target=""
              id="26"
              alt="Back Left Shoulder"
              title="Back Left Shoulder"
              href=""
              coords="601,101,587,109,577,115,574,128,569,139,566,149,577,158,587,161,604,161,614,157,625,151,637,143,650,137,658,133,656,124,655,106,644,104,629,104,614,103"
              shape="poly"
            />
            <area
              target=""
              id="27"
              alt="Back Right Shoulder"
              title="Back Right Shoulder"
              href=""
              coords="655,107,656,134,674,139,689,148,698,155,710,160,722,161,733,157,740,151,745,143,740,131,734,119,725,109,710,104,698,106,683,106"
              shape="poly"
            />
            <area
              target=""
              id="28"
              alt="Back Left Upper Arm"
              title="Back Left Upper Arm"
              href=""
              coords="565,151,560,164,557,175,550,187,545,194,569,212,577,203,583,191,590,182,596,172,604,161,589,161,577,158"
              shape="poly"
            />
            <area
              target=""
              id="29"
              alt="Back Right Upper Arm"
              title="Back Right Upper Arm"
              href=""
              coords="710,162,746,211,757,207,766,199,770,195,764,181,760,169,751,154,745,145,737,156,727,163"
              shape="poly"
            />
            <area
              target=""
              id="30"
              alt="Back Left Lower Arm"
              title="Back Left Lower Arm"
              href=""
              coords="545,194,533,211,526,226,518,242,514,253,509,271,502,278,521,292,533,268,542,257,550,245,559,235,566,223,571,211"
              shape="poly"
            />
            <area
              target=""
              id="31"
              alt="Back Right Lower Arm "
              title="Back Right Lower Arm "
              href=""
              coords="748,211,760,225,761,235,767,246,776,256,787,268,796,280,797,285,808,282,814,276,806,261,799,244,791,225,785,214,775,204,769,195,761,204"
              shape="poly"
            />
            <area
              target=""
              id="32"
              alt="Back Left Hand "
              title="Back Left Hand "
              href=""
              coords="500,278,490,285,476,297,470,306,470,311,485,300,475,324,481,333,487,326,488,335,493,330,496,335,506,323,514,312,517,300,520,293"
              shape="poly"
            />
            <area
              target=""
              id="33"
              alt="Back Right Hand "
              title="Back Right Hand "
              href=""
              coords="796,288,803,306,812,320,823,330,826,329,833,335,842,326,844,324,838,312,832,300,842,306,847,303,838,293,815,278,809,285"
              shape="poly"
            />
            <area
              target=""
              id="34"
              alt="Upper Left Back"
              title="Upper Left Back"
              href=""
              coords="656,135,656,188,649,195,626,213,614,218,607,222,605,203,601,185,598,171,605,159,619,156,634,146,646,137"
              shape="poly"
            />
            <area
              target=""
              id="35"
              alt="Upper Right Back"
              title="Upper Right Back"
              href=""
              coords="656,135,656,186,664,195,673,203,683,210,695,216,704,221,707,207,712,194,715,179,716,170,706,159,688,149,673,138"
              shape="poly"
            />
            <area
              target=""
              id="36"
              alt="Lower Left Back"
              title="Lower Left Back"
              href=""
              coords="656,188,655,241,640,256,631,253,617,254,607,259,608,244,608,226,607,220,620,215,631,209,644,200"
              shape="poly"
            />
            <area
              target=""
              id="37"
              alt="Lower Right Back"
              title="Lower Right Back"
              href=""
              coords="656,188,656,242,673,256,683,254,695,254,706,257,704,239,704,226,703,218,692,215,674,205"
              shape="poly"
            />
            <area
              target=""
              id="46"
              alt="Sacrum"
              title="Sacrum"
              href=""
              coords="655,243,640,256,658,280,673,258"
              shape="poly"
            />
            <area
              target=""
              id="38"
              alt="Left Hip"
              title="Left Hip"
              href=""
              coords="605,259,604,271,598,286,596,298,598,310,601,321,614,328,631,330,646,327,658,316,658,300,658,282,649,270,638,259,626,255,616,255"
              shape="poly"
            />
            <area
              target=""
              id="39"
              alt="Right Hip"
              title="Right Hip"
              href=""
              coords="658,281,659,317,670,323,680,326,691,324,700,320,709,312,713,305,712,291,707,269,706,255,691,255,677,257"
              shape="poly"
            />
            <area
              target=""
              id="40"
              alt="Back Left Thigh"
              title="Back Left Thigh"
              href=""
              coords="596,315,596,343,599,364,602,384,608,402,613,420,614,450,625,451,649,450,652,433,652,421,653,408,655,393,655,375,656,357,658,337,658,321,646,328,626,330,608,327"
              shape="poly"
            />
            <area
              target=""
              id="41"
              alt="Back Right Thigh"
              title="Back Right Thigh"
              href=""
              coords="659,317,658,356,661,377,665,403,665,418,665,437,665,448,667,454,698,448,701,425,704,404,707,386,713,374,715,350,715,332,715,314,715,307,703,317,689,325,673,325"
              shape="poly"
            />
            <area
              target=""
              id="42"
              alt="Left Calf"
              title="Left Calf"
              href=""
              coords="613,452,613,468,610,480,611,494,614,506,617,524,620,542,622,561,623,572,644,572,644,557,646,540,647,521,652,506,652,492,649,480,649,461,649,452"
              shape="poly"
            />
            <area
              target=""
              id="43"
              alt="Right Calf"
              title="Right Calf"
              href=""
              coords="665,454,667,464,664,472,662,487,662,503,665,524,670,536,668,554,668,568,682,568,691,566,691,551,694,535,697,518,700,503,701,488,701,470,698,455,697,449"
              shape="poly"
            />
            <area
              target=""
              id="44"
              alt="Back Left Foot"
              title="Back Left Foot"
              href=""
              coords="617,583,622,591,631,591,647,592,652,601,650,612,643,615,632,615,622,609,613,604,601,600,593,591,593,582,604,580"
              shape="poly"
            />
            <area
              target=""
              id="45"
              alt="Back Right Foot"
              title="Back Right Foot"
              href=""
              coords="667,590,682,587,692,581,707,586,721,587,725,593,722,601,713,604,700,607,689,608,676,613,665,608,662,598"
              shape="poly"
            />
            <area
              target=""
              id="47"
              alt="Left Ankle "
              title="Left Ankle "
              href=""
              coords="617,582,623,590,632,590,643,591,648,592,648,581,644,573,623,571"
              shape="poly"
            />
            <area
              target=""
              id="48"
              alt="Right Ankle"
              title="Right Ankle"
              href=""
              coords="667,569,665,588,677,588,690,581,690,567,677,571"
              shape="poly"
            />
            <area
              target=""
              id="51"
              alt="Left Eye"
              title="Left Eye"
              href=""
              coords="103,61,23"
              shape="circle"
            />
            <area
              target=""
              id="52"
              alt="Right Eye"
              title="Right Eye"
              href=""
              coords="306,61,23"
              shape="circle"
            />
            <area
              target=""
              id="54"
              alt="Right Bottom Foot"
              title="Right Bottom Foot"
              href=""
              coords="314,510,321,515,331,516,338,523,344,530,343,540,341,555,338,570,338,580,334,590,328,594,319,592,318,582,319,565,316,547,313,538,313,521"
              shape="poly"
            />
            <area
              target=""
              id="53"
              alt="Left Bottom Foot"
              title="Left Bottom Foot"
              href=""
              coords="54,527,59,520,67,515,72,513,78,512,81,513,83,518,83,528,83,537,81,547,78,555,78,565,78,580,76,589,71,594,62,594,59,584,56,569,52,549,54,527"
              shape="poly"
            />
          </map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      selectedAreas: [],
    };
  },
  mounted() {
    window.$(".bodyMap").maphilight({ stroke: false });
    this.selectedAreas = this.clientQuestionnaire.body_locations;
    this.toggleHighlightedAreas();
  },
  computed: {
    ...mapState({
      clientQuestionnaire: (state) =>
        state.patients.symptomsDiary.currentClientQuestionnaire.data,
    }),
    isFilled: function () {
      return this.clientQuestionnaire.isFilled;
    },
  },
  methods: {
    ...mapActions({
      setCurrentClientQuestionnaire:
        "patients/symptomsDiary/setCurrentClientQuestionnaire",
    }),
    handleSelectedArea: function (event) {
      if (this.isFilled) {
        return;
      }
      const triggerId = event.target.id;
      let index = this.selectedAreas.findIndex((item) => {
        return item == triggerId;
      });
      if (index !== -1) {
        this.selectedAreas.splice(index, 1);
      } else {
        this.selectedAreas.push(triggerId);
      }
      this.setCurrentClientQuestionnaire({
        ...this.clientQuestionnaire,
        body_locations: this.selectedAreas,
      });
      this.highlightArea(triggerId);
    },
    highlightArea: function (id) {
      var data = window.$(`#${id}`).mouseout().data("maphilight") || {};
      data.alwaysOn = !data.alwaysOn;
      if (data.alwaysOn === false) {
        data.fillColor = "000000";
        data.fillOpacity = "0.2";
      } else {
        data.fillColor = "002200";
        data.fillOpacity = "0.6";
      }
      window
        .$(`#${id}`)
        .data("maphilight", data)
        .trigger("alwaysOn.maphilight")
        .trigger("fillColor.maphilight")
        .trigger("fillOpacity.maphilight");
    },
    toggleHighlightedAreas: function () {
      this.selectedAreas.forEach((body_location) => {
        this.highlightArea(body_location);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.mapArea {
  min-width: 850px !important;
  min-height: 640px !important;
  max-width: 850px !important;
  max-height: 640px !important;
}
.center-div {
  width: 100%;
  display: grid;
  place-items: center;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
</style>
