<template>
  <vue-tags-input
    ref="activity_categories_ids"
    id="activity_categories_ids"
    name="activity_categories_ids"
    class="form-control"
    :disabled="disabled"
    v-model="activityCategoryTag"
    :tags="activityCategoryTags"
    :autocomplete-items="activityCategoryFilteredItems"
    :add-only-from-autocomplete="true"
    :autocomplete-min-length="0"
    placeholder=""
    @tags-changed="setTags"
  />
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  props: {
    activityCategoryTags: Array,
    activityCategoryAutocompleteItems: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueTagsInput,
  },
  data() {
    return {
      activityCategoryTag: "",
      resizeObserver: null,
      categoryMultiselectHeight: 40,
    };
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs["activity_categories_ids"].$el);
  },
  mounted() {
    this.observeMutation();
    this.observeHeight();
  },
  computed: {
    activityCategoryFilteredItems() {
      return this.activityCategoryAutocompleteItems.filter((i) => {
        return (
          i.text
            .toLowerCase()
            .indexOf(this.activityCategoryTag.toLowerCase()) != -1
        );
      });
    },
  },
  methods: {
    setTags: function (newTags) {
      this.$emit("setTags", newTags);
    },
    observeHeight() {
      const vm = this;
      this.resizeObserver = new ResizeObserver(() => {
        const domElement =
          this.$refs["activity_categories_ids"].$el.getElementsByClassName(
            "ti-autocomplete"
          );
        this.categoryMultiselectHeight =
          vm.$refs["activity_categories_ids"].$el.clientHeight;
        if (domElement.length) {
          domElement[0].style.bottom = `${vm.$refs["activity_categories_ids"].$el.clientHeight}px`;
        }
      });

      this.resizeObserver.observe(this.$refs["activity_categories_ids"].$el);
    },
    observeMutation() {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "childList") {
            mutation.addedNodes.forEach((node) => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                node.style.bottom = `${this.categoryMultiselectHeight}px`;
              }
            });
          }
        });
      });
      observer.observe(this.$refs["activity_categories_ids"].$el, {
        childList: true,
      });
    },
  },
};
</script>

<style lang="scss">
#activity_categories_ids {
  margin: auto;
  height: fit-content;
  padding: 0;
  max-width: 650px;
  .ti-input {
    border: 0 !important;
    flex: 1 !important;
    .ti-tags {
      li {
        height: 32px;
        margin-top: 0;
      }
    }
  }
  .ti-new-tag-input-wrapper input {
    height: 100%;
  }
  .ti-autocomplete {
    border: 1px solid #ccc;
    z-index: 50 !important;
  }
}
</style>
